




























































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { mask } from 'vue-the-mask'

@Component({
  components: { PageTab, ListView },
  directives: { mask },
})
export default class Edit2 extends Vue {
  public $refs: Vue['$refs'] & {
    editConfigForm: {
      validate: any
      reset: any
    }
    editLineForm: {
      validate: any
      reset: any
    }
    addProductForm: {
      validate: any
      reset: any
    }
  }

  private loading = true

  private editRefillConfigurationDialog = false
  private editRefillLineDialog = false
  private addProductDialog = false
  private deleteLineDialog = false

  private inputPeriodFrom = null
  private inputPeriodTo = null

  private quantityWanted = 0
  private quantityToOrder = 0

  private editConfig = {
    name: '',
    location: {
      id: null,
      name: '',
    },
    active: false,
    notifyRequired: false,
  }

  private productIdToAdd = ''
  private productIdToDelete = ''

  private headers = []
  private topActions = []
  private rowActions = []

  private fetchedLocations = []

  private rules = {
    periodRules: [
      (v) =>
        !v ||
        /^(0[1-9]|1[0-2])\.(0[1-9]|[12]\d|3[01])$/.test(v) ||
        this.$t('c:stock-refill:Invalid period (ex: MM.dd)'),
    ],
  }

  private activeLine = {
    id: null,
    quantityWanted: 0,
    productId: null,
    quantity: 0,
    quantityToOrder: 0,
    refillId: null,
  }

  private refillConfiguration = {
    id: '',
    name: '',
    locationName: '',
    status: 'Inactive',
    periodFrom: null,
    periodTo: null,
    active: false,
    locationId: '',
    notifyRequired: false,
    location: {
      id: null,
      name: '',
    },
  }

  created() {
    this.fetchConfiguration()
    this.setHeaders()
    this.setTopActions()
    this.setRowActions()
    this.listLocations()
  }

  private fetchConfiguration() {
    this.$axios
      .get(`/v4/site/stock-refill-configurations/${this.$route.params.id}`)
      .then((res) => {
        this.refillConfiguration = res.data.data
        this.inputPeriodFrom = this.refillConfiguration.periodFrom
        this.inputPeriodTo = this.refillConfiguration.periodTo
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading stock refill configurations')
      })
      .finally(() => {
        this.loading = false
      })
  }

  private editConfigDialog() {
    this.editConfig = {
      name: this.refillConfiguration.name,
      active: this.refillConfiguration.active,
      notifyRequired: this.refillConfiguration.notifyRequired,
      location: {
        id: this.refillConfiguration.locationId ? this.refillConfiguration.locationId : -1,
        name: this.refillConfiguration.locationName,
      },
    }
    this.editRefillConfigurationDialog = true
  }

  private editLineDialog(line): void {
    this.activeLine = line
    this.activeLine.refillId = this.refillConfiguration.id
    this.quantityWanted = this.activeLine.quantityWanted
    this.editRefillLineDialog = true
  }

  private deleteProductDialog(productId) {
    this.deleteLineDialog = true
    this.productIdToDelete = productId
  }

  private listLocations() {
    this.$axios
      .get('/v4/site/stock-refill-configurations/locations')
      .then((res) => {
        res.data.data.items.forEach((location) => {
          if (location.id === null && location.name !== '') {
            location.id = -1
          }
          if (location.name === this.refillConfiguration.locationName) {
            this.editConfig.location = {
              id: location.id,
              name: location.name,
            }
          }
          this.fetchedLocations.push(location)
        })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error loading locations')
      })
  }

  private get url() {
    return '/v4/site/stock-refill-configurations/lines/for-refill'
  }

  private get query() {
    return {
      refillId: this.$route.params.id,
      locationId: this.refillConfiguration.locationId,
    }
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'ID',
        value: 'id',
        filter: { disable: false },
      },
      {
        text: 'Product No',
        value: 'productId',
        filter: { disable: true },
      },
      {
        text: 'Product',
        value: 'productName',
        filter: { disable: true },
      },
      {
        text: 'c:stock-refill:Quantity wanted',
        value: 'quantityWanted',
        filter: { disable: true },
      },
      {
        text: 'c:stock-refill:Quantity in stock',
        value: 'quantity',
        filter: { disable: true },
      },
      {
        text: 'c:stock-refill:Quantity to order',
        value: 'quantityToOrder',
        filter: { disable: true },
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]
  }

  private setTopActions() {
    this.topActions = [
      {
        id: 'edit',
        click: () => {
          this.editConfigDialog()
        },
      },
      {
        id: 'add product',
        template: 'new',
        click: () => {
          this.addProductDialog = true
        },
      },
    ]
  }

  private setRowActions() {
    this.rowActions = [
      {
        id: 'edit',
        click: (_, item) => {
          this.editLineDialog(item)
        },
      },
      {
        id: 'delete',
        click: (_, item) => {
          this.deleteProductDialog(item.id)
        },
      },
    ]
  }

  private submitEditConfig() {
    if ((this.inputPeriodFrom && !this.inputPeriodTo) || (!this.inputPeriodFrom && this.inputPeriodTo)) {
      vxm.alert.error('Either both periods or none should be empty')
      return
    }
    if (this.$refs.editConfigForm.validate()) {
      this.loading = true
      this.refillConfiguration.name = this.editConfig.name
      this.refillConfiguration.locationId = this.editConfig.location.id
      this.refillConfiguration.active = this.editConfig.active
      this.refillConfiguration.notifyRequired = this.editConfig.notifyRequired
      this.refillConfiguration.periodFrom = this.inputPeriodFrom
      this.refillConfiguration.periodTo = this.inputPeriodTo
      this.$axios
        .put(`/v4/site/stock-refill-configurations/${this.$route.params.id}`, this.refillConfiguration)
        .then(() => {
          this.fetchConfiguration()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error saving refill')
        })
        .finally(() => {
          this.editRefillConfigurationDialog = false
          this.loading = false
        })
    }
  }

  private submitEditLine() {
    if (this.$refs.editLineForm.validate()) {
      this.activeLine.quantityWanted = this.quantityWanted
      this.loading = true
      this.$axios
        .put(`/v4/site/stock-refill-configurations/lines/${this.activeLine.id}`, this.activeLine)
        .then(() => {
          this.loading = false
          this.editRefillLineDialog = false
          this.$refs.editLineForm.reset()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error saving refill line')
        })
    }
  }

  private addProduct() {
    if (this.$refs.addProductForm.validate()) {
      this.loading = true
      const data = {
        quantityWanted: this.quantityWanted,
        quantityToOrder: this.quantityToOrder,
        productId: this.productIdToAdd,
        refillId: this.refillConfiguration.id,
      }
      this.$axios
        .post('/v4/site/stock-refill-configurations/lines', data)
        .then(() => {
          this.$refs.addProductForm.reset()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error adding product')
        })
        .finally(() => {
          this.loading = false
          this.addProductDialog = false
        })
    }
  }

  private deleteProduct(productId) {
    this.loading = true
    this.$axios
      .delete(`/v4/site/stock-refill-configurations/lines/${productId}`)
      .then(() => {
        this.deleteLineDialog = false
        this.loading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error deleting product')
      })
  }

  private formatActive(status) {
    if (status === 1) {
      return this.$t('Active')
    }
    return this.$t('Inactive')
  }
}
